/* eslint-disable */
import HttpService from '../HttpService';
export default class AssessmentAnswer{
  constructor() {/* eslint-disable */
    this._httpService = new HttpService(`survey`);
  }

  async CreateAssessmentAnswer(data) {
    return await this._httpService.post('/assessment_answer/registerAnswers', data);
  }
}


/* eslint-disable */
import HttpService from '../HttpService';
export default class AssessmentQuestion {
  constructor() {/* eslint-disable */
    this._httpService = new HttpService(`survey`);
  }

  async FindAllByAssessmentId(assessmentId) {
    return await this._httpService.get(`/assessment_question/findAllByAssessmentId/${assessmentId}?active=true`);
  }
}


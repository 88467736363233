<template>
  <v-container style="height: 100%" class="bg-glass-effect-lighter px-3 pt-15" fluid>
    <Back v-if="!busy" :action="() => $router.go(-1)"/>
    <v-row justify="center" v-if="questions && questions.length">
      <v-col cols="12" class="px-5 pb-0 my-0">
        <h1 style="font-size: 32px"  class="main-title pl-1">{{ assessment && assessment.name ? assessment.name : 'Questionário' }}</h1>
        <p class="main-subtitle mt-1 pl-1">{{ questions[0].assessmentQuestionTopicDescription }}</p>
      </v-col>

      <v-col cols="12">
        <v-card elevation="8" rounded="lg" class="mx-2 mb-3 mt-5 card-layout" v-for="question in questions" :key="question.id">
          <v-card-title class="main-subtitle">
            <v-row>
              <v-col class="pl-2" cols="1">
                <i class="far fa-comment pl-1" style="color: var(--v-primary); font-size: 18px;"></i>
              </v-col>
              <v-col cols="11">
                <p style="word-break: normal">
                  {{ question && question.description ? question.description : ' - ' }}
                </p>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-subtitle>
            <v-chip
                v-if="question && question.mandatory"
                class="my-2 pr-2"
                color="primary"
                small
            >
              <v-icon left color="white">
                fas fa-edit
              </v-icon>
              <p class="ma-0" style="color: white;">Obrigatório</p>
            </v-chip>
          </v-card-subtitle>

          <v-card-text>
            <v-row v-if="question && question.questionType">
              <v-col cols="12" v-if="question.questionType === 'TEXT'">
                <v-text-field
                    maxlength="255"
                    label="Resposta"
                    v-model="question.answer"
                    @input="verifyQuestionIsMandatoryAndNotEmpty"
                ></v-text-field>
              </v-col>

              <v-col cols="12" v-if="question.questionType === 'NUMBER'">
                <v-text-field
                    v-mask="'###########'"
                    maxlength="11"
                    label="Resposta"
                    placeholder="Somente números"
                    v-model="question.answer"
                    @input="verifyQuestionIsMandatoryAndNotEmpty"
                ></v-text-field>
              </v-col>

              <v-col cols="12" v-if="question.questionType === 'MULTIPLE'">
                <v-select
                    placeholder="Selecione uma ou mais opções"
                    multiple
                    chips
                    :items="convertComboValues(question.triggers)"
                    v-model="question.answer"
                    @change="verifyQuestionIsMandatoryAndNotEmpty">
                </v-select>
              </v-col>

              <v-col cols="12" v-if="question.questionType === 'SELECT'">
                <v-select
                    placeholder="Selecione uma das opções"
                    :items="convertComboValues(question.triggers)"
                    v-model="question.answer"
                    @change="verifyQuestionIsMandatoryAndNotEmpty">
                </v-select>
              </v-col>

              <v-col cols="12" v-if="question.questionType === 'COIN'">
                <v-text-field
                    type="number"
                    label="Preencha com o valor"
                    placeholder="Exemplo: 10,00"
                    prefix="R$"
                    v-model="question.answer"
                    @input="verifyQuestionIsMandatoryAndNotEmpty">
                </v-text-field>
              </v-col>

              <v-col cols="12" v-if="question.questionType === 'BOOLEAN'">
                <v-radio-group row v-model="question.answer" @change="verifyQuestionIsMandatoryAndNotEmpty">
                  <v-radio label="Verdadeiro" :value="convertBooleanValue('Verdadeiro')"></v-radio>
                  <v-radio label="Falso" :value="convertBooleanValue('Falso')"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="11" class="mb-2 mt-0 pt-0">
        <GenericBtn
          style="width: 100%"
          :btn-props="{
          block: true,
          large: true,
          type: 'submit',
          color: 'primary',
          disabled: isDisabledBtn
        }" :onClick="() => registerAnswer()">Responder</GenericBtn>
      </v-col>
    </v-row>

    <!--dialog-->
    <v-row>
      <v-col cols="12">
        <v-dialog v-model="dialog.show" persistent max-width="400px">
          <v-card :style="dialog.style" rounded="lg">
            <v-row justify="center" class="ma-0 px-0 pt-4" style="height: 100%;">
              <v-col cols="11" align-self="center">
                <v-row>
                  <v-col cols="12">
                    <v-btn icon :color="dialog.colorDefault" @click="$router.go(-1)">
                      <v-icon large>fas fa-times</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col col="12" align="center">
                    <img width="100px" height="100px"
                         src="/img/icons/commons/checkmark.svg"
                    />
                    <h2
                        class="pb-5 generic-title"
                        style="text-align: center; font-weight: bold;"
                        :style="{color: dialog.colorDefault}"
                    >Informação</h2>
                    <p
                        class="px-5 generic-text"
                        style="text-align: center; font-size: 20px"
                        :style="{color: dialog.colorDefault}"
                    >O questionário foi respondido com sucesso.</p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="11" align-self="center">
                <v-btn class="btn btn-great" :color="dialog.colorDefault" block x-large rounded @click="$router.go(-1)">Ok</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row class="py-0 my-0" justify="center" v-if="!busy && (!questions || !questions.length)">
      <v-col cols="12" class="px-5 pb-0 my-0">
        <h1 style="font-size: 32px;"  class="main-title">Questionário</h1>
      </v-col>
      <v-col cols="12" class="px-5 pb-0 my-0">Não existem questões ativas cadastradas.</v-col>
    </v-row>
  </v-container>
</template>

<script>
import Back from '@/components/covid/back-arrow';
import {mapMutations} from "vuex";
import AssessmentQuestion from "@/services/survey/AssessmentQuestion";
import AssessmentAnswer from "@/services/survey/AssessmentAnswer";
import GenericBtn from "@/components/generic-btn/index"

export default {
  name: "assessment",
  components: {
    Back,
    GenericBtn
  },
  data: () => ({
    busy: false,
    assessment:{
      id: 0,
      name: 'Questionário'
    },
    dialog: {
      message: '',
      show: false,
      colorDefault: 'primary',
      style: {
        color: '#532E88',
        backgroundColor: '#EEEDF7',
        height: '80vh'
      }
    },
    isDisabledBtn: true,
    optionsBoolean: [],
    questions: []
  }),
  beforeCreate() {
    this._assessmentQuestionService = new AssessmentQuestion()
    this._assessmentAnswerService = new AssessmentAnswer()
  },
  async created () {
    this.assessment = {
      id: this.$route.params.assessmentId,
      name: this.$route.params.assessmentName,
    }

    this.busy = true;
    this.loading(this.busy)
    await this.loadAssessmentQuestions()
    this.busy = false;
    this.loading(this.busy)
  },
  methods:{
    ...mapMutations(["showmsg", "loading"]),
    async registerAnswer(){
      if(!this.isValidCoinFields()){
        this.showmsg({text: "Valor inválido para o campo monetário (R$).", type: "error"})
        return;
      }

      const assessmentAnswers = this.createAnswers()
      if(!assessmentAnswers || assessmentAnswers.length === 0){
        this.showmsg({text: "Não foi possível gerar as respostas.", type: "error"})
      }else{

        this.loading(true)
        let totalScore = 0
        assessmentAnswers.forEach(assessmentAnswer => {
          totalScore += assessmentAnswer.score
        })
        const assessmentAnswerDto = {
          assessmentAnswers: assessmentAnswers,
          totalScore: totalScore
        }

        await this._assessmentAnswerService.CreateAssessmentAnswer(assessmentAnswerDto)
            .then(response => {
              if(response.status === 200 && response.data){
                this.$router.push({name: 'assessment-feedback', params: {feedback: response.data}, query: {feedback: response.data.id}})
              }
            })
            .catch(() => {
              this.showmsg({text: "Não foi possível registrar as respostas do questionário.", type: "error"})
            }).finally(() => {
              this.loading(false)
            })
      }
    },
    async loadAssessmentQuestions(){
      await this._assessmentQuestionService.FindAllByAssessmentId(this.assessment.id)
          .then(response => {
            if(response.data){
              this.questions = response.data
              this.questions = this.questions.filter((question) => question.active);
            }
          })
          .catch(() => {
            this.showmsg({text: "Não foi possível carregar as questões da pesquisa.", type: "error"})
          })
    },
    createAnswers(){
      let answers = []
      let userId = this.$auth.user().id
      this.questions.forEach(question => {
        let score = 0;

        question.triggers.forEach(trigger => {
          if (question.questionType === 'MULTIPLE'){
            question.answer.forEach(answer => {
              score += this.calculateScore(trigger, answer)
            })
          } else{
            score += this.calculateScore(trigger, question.answer)
          }
        })

        if(question.answer && question.questionType === 'MULTIPLE' && question.answer.length > 0){
          const answerJoined = question.answer.join(',')
          delete question['answer']
          question.answer = answerJoined
        }

        let assessmentAnswer = {
          userId: userId,
          answer: question.answer,
          assessmentQuestionId: question.id,
          assessmentId: question.assessmentId,
          score: score
        }

        delete question['answer']
        answers.push(assessmentAnswer)
      })

      return answers
    },
    convertBooleanValue(value){
      return value === 'Verdadeiro' ? 'true' : 'false';
    },
    convertComboValues(triggers){
      let values = []

      triggers.forEach(trigger => {
        values.push(trigger.value)
      })

      return values
    },
    calculateScore(trigger, answer) {
      let triggerScore = trigger.score ? parseInt(trigger.score) : 0;
      if (trigger.condition === 'EQUALS') {
        if (trigger.value === answer)
          return triggerScore
      } else if (trigger.condition === 'CONTAINS') {
        if (answer.includes(trigger.value))
          return triggerScore
      } else if (trigger.condition === 'GREATER_THAN') {
        if (parseInt(answer) > parseInt(trigger.value))
          return triggerScore
      } else if (trigger.condition === 'LESS_THAN') {
        if (parseInt(answer) < parseInt(trigger.value))
          return triggerScore
      }
      return 0
    },
    convertComboSelectedValues(values){
      return values.join(',')
    },
    isValidCoinFields(){
      let countInvalidFields = 0;
      this.questions.forEach(question => {
        if(question.questionType === 'COIN' && !this.$util.validateDecimalValue(question.answer)){
          countInvalidFields++;
        }
      })
      return countInvalidFields == 0
    },
    verifyQuestionIsMandatoryAndNotEmpty(){
      let countTotalMandatory = 0

      this.questions.forEach(question => {
        if(question.mandatory && !question.answer){
          countTotalMandatory++;
        }
      })

      if(countTotalMandatory == 0){
        this.isDisabledBtn = false
      }else{
        this.isDisabledBtn = true
      }
    }
  }
}
</script>

<style scoped>
.card-layout {
  border-radius: 12px !important;
  margin-bottom: 30px;
}
</style>


